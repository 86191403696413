<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo/>
                <h2 class="brand-text text-primary ml-1">
                    سامانه مدیریت
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                    lg="8"
                    class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                            fluid
                            :src="imgUrl"
                            alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                    lg="4"
                    class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                        sm="8"
                        md="6"
                        lg="12"
                        class="px-xl-2 mx-auto"
                >
                    <b-card-title
                            title-tag="h2"
                            class="font-weight-bold mb-1"
                    >
                        به سامانه مدیریتی ارز دیجیتال خوش آمدید 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        برای استفاده از سامانه ابتدا وارد شوید
                    </b-card-text>

                    <!-- form -->
                    <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="login"
                    >
                        <!-- email -->
                        <b-form-group
                                label="نام کاربری"
                                label-for="login-email"
                        >
                            <b-form-input
                                    id="login-email"
                                    v-model="email"
                                    :state="email.length === 0 ? false:null"
                                    placeholder="john@example.com"
                            />
                        </b-form-group>

                        <!-- forgot password -->
                        <b-form-group
                                label="رمز عبور"
                                label-for="login-password"
                        >
                            <!--                                <div class="d-flex justify-content-between">-->
                            <!--                                    <label for="login-password">رمز عبور</label>-->
                            <!--                                    <b-link :to="{name:'auth-forgot-password-v2'}">-->
                            <!--                                        <small>فراموشی رمز عبور</small>-->
                            <!--                                    </b-link>-->
                            <!--                                </div>-->
                            <b-input-group
                                    class="input-group-merge"
                                    :class="password.length === 0 ? 'is-invalid':null"
                            >
                                <b-form-input
                                        id="login-password"
                                        v-model="password"
                                        :state="password.length === 0 ? false:null"
                                        class="form-control-merge"
                                        :type="togglePasswordVisibility? 'text':'password'"
                                        placeholder="············"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                            class="cursor-pointer"
                                            :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility = !togglePasswordVisibility"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <!--                            <small class="text-danger">{{ errorMessage }}</small>-->
                        </b-form-group>
                        <small class="text-danger">{{ errorMessage }}</small>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                            >
                                مرا به خاطر بسپار
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- submit buttons -->
                        <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="showLoading"
                        >
                            <b-spinner v-if="showLoading" small/>
                            {{!showLoading ? 'ورود':''}}
                        </b-button>
                    </b-form>

                </b-col>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        BSpinner,
    } from 'bootstrap-vue'
    import store from '@/store/index'

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardText,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            BSpinner,
            VuexyLogo,
        },
        data() {
            return {
                status: '',
                userEmail: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                togglePasswordVisibility: false,
                show: false,
                email: '',
                emailError: '',
                passError: '',
                password: '',
                res: '',
                errorMessage: '',
                OSName: 'Unknown',
                borwserType: '',
                deviceType: '',
                showLoading: false
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.togglePasswordVisibility ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        methods: {
            checkEmail() {

                if (!this.email) {
                    this.emailError = 'نام کاربری اجباری';
                } /*else if (!this.validEmail(this.email)) {
                    this.emailError = 'ایمیل صحیح وارد نمایید';
                }*/ else {
                    this.emailError = ''
                }
                if (!this.emailError.length) {
                    return true;
                }
            },
            validEmail(email) {
                const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return re.test(email);
            },
            receive(e) {
                this.email = e
                this.checkEmail(e)
            },
            receive1(e) {
                this.password = e
            },
            login() {

                if (this.checkEmail()) {
                    const article = {
                        "email": this.email,
                        "password": this.password,
                        "osType": this.OSName,
                        "deviceType": this.deviceType,
                        "browser": this.borwserType
                    };
                    this.showLoading = true
                    this.$axios.post('/admins/login', article)
                        .then(response => {
                            this.res = response.data;
                            if (this.res.message.indexOf('successfully') > -1) {
                                localStorage.token = `Bearer ${this.res.token}`
                                this.state.token = localStorage.token
                                this.$axios.defaults.headers.common['Authorization'] = this.state.token
                                this.$router.push('/')
                            }
                        }).catch(error => {
                            this.showLoading = false
                            if (error.response) {
                                if (error.response.data.status === 'UNAUTHORIZED') {
                                    this.errorMessage = 'نام کاربری یا رمز عبور اشتباه است';
                                } else if (error.response.data.status === 'NOT_FOUND') {
                                    this.errorMessage = 'نام کاربری یافت نشد.'
                                }
                            }

                        })
                }
            },
            detectOs() {
                var Name = "Unknown OS";
                if (navigator.userAgent.indexOf("Win") > -1)
                    Name = "WINDOWS";
                if (navigator.userAgent.indexOf("Mac") > -1)
                    Name = "MACINTOSH";
                if (navigator.userAgent.indexOf("Linux") > -1)
                    Name = "LINUX";
                if (navigator.userAgent.indexOf("Android") > -1)
                    Name = "ANDROID";
                if (navigator.userAgent.indexOf("like Mac") > -1)
                    Name = "IOS";
                this.OSName = Name
                console.log('Your OS: ' + this.OSName);
            },
            detectBrowser() {
                // Opera 8.0+
                let isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                if (isOpera) {
                    this.borwserType = 'OPERA'
                }

                // Firefox 1.0+
                let isFirefox = typeof InstallTrigger !== 'undefined';
                if (isFirefox) {
                    this.borwserType = 'FIREFOX'
                }

                // Safari 3.0+ "[object HTMLElementConstructor]"
                let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                    return p.toString() === "[object SafariRemoteNotification]";
                })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
                if (isSafari) {
                    this.borwserType = 'SAFARI'
                }
                // Internet Explorer 6-11
                let isIE = /*@cc_on!@*/false || !!document.documentMode;
                if (isIE) {
                    this.borwserType = 'IE'
                }
                // Edge 20+
                let isEdge = !isIE && !!window.StyleMedia;
                if (isEdge) {
                    this.borwserType = 'EDGE'
                }
                // Chrome 1 - 79
                let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isChrome) {
                    this.borwserType = 'CHROME'
                }
                // Edge (based on chromium) detection
                let isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
                if (isEdgeChromium) {
                    this.borwserType = 'EDGECHROMIUM'
                }
                if (this.borwserType === '') {
                    this.borwserType = 'CHROME'
                }
                console.log(this.borwserType)
            },
            detectDevice() {
                const ua = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    this.deviceType = 'TABLET'
                }
                if (
                    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                        ua
                    )
                ) {
                    this.deviceType = 'MOBILE'
                } else {
                    this.deviceType = 'DESKTOP'
                }
                console.log(this.deviceType)
            }
        },
        mounted() {
            this.detectOs();
            this.detectBrowser();
            this.detectDevice()
        }

    }
</script>

<style lang="scss" src="../@core/scss/vue/pages/page-auth.scss"/>
